import { identifyKnockUser } from "@/app/actions/auth.actions"
import va from "@vercel/analytics"
import posthog from "posthog-js"

export const track = (event: string, properties?: any) => {
  va.track(event, {
    ...properties,
  })
  posthog.capture(event, properties)
}

export const identify = (userId: string, properties?: any) => {
  posthog.identify(userId, properties)
  identifyKnockUser({
    userId,
    pro_display_name: properties?.full_name || properties?.name,
    pro_picture_url: properties?.picture,
    pro_email: properties?.email,
  })
}

export const flushAnalytics = () => {
  posthog.reset()
}
