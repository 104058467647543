import { FeedCardDirectionAwareImage } from "@/components/feed-card/feed-card-direction-aware-image"
import { FeedCardImage } from "@/components/feed-card/feed-card-image"
import { FeedCardItem } from "@/components/feed-card/feed-card-item"
import { FeedCardOveraly } from "@/components/feed-card/feed-card-overlay"

export const FeedCard = {
  Item: FeedCardItem,
  Overlay: FeedCardOveraly,
  Image: FeedCardImage,
  DirectionHoverImage: FeedCardDirectionAwareImage,
}
