"use client"
import { createContext, useCallback, useState } from "react"
import {
  removeGenerationsLike,
  upsertGenerationsLike,
} from "@/app/actions/likes.actions"
import { track } from "@/services/analytics"
import { Sentry } from "@/services/sentry"
import { useRootStore } from "@/store/root-store"
import {
  GenerationsTableRow,
  LikesTableRow,
  LikesTableRowWithGeneration,
} from "@/utils/types/database"
import { useSupabaseClient, useUser } from "@supabase/auth-helpers-react"
import { PostgrestError, User } from "@supabase/supabase-js"
import { useRouter } from "@/lib/i18n"

export const defaultCurrentUserLikesValue = {
  getCurrentUserLikes: async () => {},
  toggleLike: async () => {
    return Promise.resolve({ data: null, error: null })
  },
  deleteLike: async () => {
    return Promise.resolve({ data: null, error: null })
  },
  createLike: async () => {
    return Promise.resolve({ data: null, error: null })
  },
  isFetchingLikes: false,
  likes: {},
}

type GetCurrentUserLikesArgs = {
  generationIds?: number[]
  abortController?: AbortController
  user: User | null
}
export interface CurrentUserLikesContextValue {
  getCurrentUserLikes: (args: GetCurrentUserLikesArgs) => Promise<void>
  toggleLike: (
    generation: GenerationsTableRow,
    isLiked: boolean,
  ) => Promise<{
    data: LikesTableRowWithGeneration | null
    error: PostgrestError | Error | null
  }>
  deleteLike: (generationId: number) => Promise<{
    data: LikesTableRow | null
    error: PostgrestError | Error | null
  }>
  createLike: (generation: GenerationsTableRow) => Promise<{
    data: LikesTableRowWithGeneration | null
    error: PostgrestError | Error | null
  }>
  isFetchingLikes: boolean
  likes: Record<number, LikesTableRow>
}

export const CurrentUserLikesContext =
  createContext<CurrentUserLikesContextValue>(defaultCurrentUserLikesValue)

export function CurrentUserLikesProvider(props: { children: React.ReactNode }) {
  const [isFetchingLikes, setIsFetchingLikes] = useState(true)
  const user = useUser()
  const router = useRouter()
  const supabaseClient = useSupabaseClient()

  const { likes, removeLikeByGenerationId, appendLike, appendLikes } =
    useRootStore((state) => state)

  const getCurrentUserLikes = useCallback(
    async ({
      generationIds,
      user: currentUser,
      abortController,
    }: GetCurrentUserLikesArgs) => {
      if (!currentUser?.id) {
        console.log("No user provided")
        return
      }

      setIsFetchingLikes(true)
      try {
        const queryPromise = supabaseClient
          .from("generations_likes")
          .select(`*`)
          .eq("user_id", currentUser.id)

        if (!generationIds?.length) {
          queryPromise.range(0, 50)
        }

        if (generationIds?.length) {
          queryPromise.in("generation_id", generationIds)
        }

        if (abortController) {
          queryPromise.abortSignal(abortController.signal)
        }

        const { data: userLikes, error } = await queryPromise

        if (error) {
          console.error("error getting user likes", error)
          return
        }

        appendLikes(userLikes)
      } catch (error) {
        console.error("error getting user likes", error)
      } finally {
        setIsFetchingLikes(false)
      }
    },
    [appendLikes, setIsFetchingLikes, supabaseClient],
  )

  const createLike = useCallback(
    async (generation: GenerationsTableRow) => {
      if (!user?.id) {
        return { data: null, error: new Error("User not logged in") }
      }

      const { data, error } = await upsertGenerationsLike({
        generationId: generation.id,
        userId: user.id,
      })

      return { data, error }
    },
    [user?.id],
  )

  const deleteLike = useCallback(
    async (generationId: number) => {
      if (!user?.id) {
        return { data: null, error: new Error("User not logged in") }
      }

      const { data, error } = await removeGenerationsLike({
        generationId,
        userId: user.id,
      })

      return { data, error }
    },
    [user?.id],
  )

  const toggleLike = useCallback(
    async (generation: GenerationsTableRow, isLiked: boolean) => {
      if (!generation.id) {
        return { data: null, error: new Error("No generation") }
      }

      if (!user?.id) {
        track("failed_like_button_click_because_user_not_logged_in", {
          generationId: generation.id,
        })
        router.push("/login", {
          scroll: false,
        })
        return { data: null, error: new Error("User not logged in") }
      }

      track("like_button_clicked", {
        generationId: generation.id,
        isLiked,
      })

      if (isLiked) {
        const { data: removedLike, error: unlikeError } = await deleteLike(
          generation.id,
        )

        if (unlikeError) {
          console.error("Error unliking generation:", unlikeError)
          Sentry.captureException(unlikeError)
        }
        if (removedLike) {
          removeLikeByGenerationId(generation.id)
        }
        return { data: removedLike, error: unlikeError }
      } else {
        const { data: addedLike, error: likeError } =
          await createLike(generation)

        if (likeError) {
          console.error("Error liking generation:", likeError)
          Sentry.captureException(likeError)
        }

        if (addedLike) {
          appendLike(addedLike)
        }
        return { data: addedLike, error: likeError }
      }
    },
    [
      user?.id,
      appendLike,
      removeLikeByGenerationId,
      router,
      createLike,
      deleteLike,
    ],
  )

  return (
    <CurrentUserLikesContext.Provider
      value={{
        getCurrentUserLikes,
        toggleLike,
        deleteLike,
        createLike,
        isFetchingLikes,
        likes,
      }}
    >
      {props.children}
    </CurrentUserLikesContext.Provider>
  )
}
