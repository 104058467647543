"use client"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { track } from "@/services/analytics"
import { getGenerationImage } from "@/utils/helpers"
import { GenerationsTableRow } from "@/utils/types/database"
import { FlagIcon } from "lucide-react"
import { useState } from "react"
import { toast } from "sonner"

interface FeedCardFlagButtonProps {
  buttonClassNames?: string
  generation: GenerationsTableRow
}
export function FeedCardFlagButton({
  buttonClassNames,
  generation,
}: FeedCardFlagButtonProps) {
  const [open, setOpen] = useState(false)

  const image = getGenerationImage(generation.images)

  const onFlagClick = async (e: React.MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()

    track("flag_image", {
      generation_id: generation.id,
      generation_image_url: image.url,
    })

    try {
      const response = await fetch("/api/flag", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          generation_id: generation.id,
          generation_image_url: image.url,
          flag_reason: "UNKNOWN",
          fla_type: "t2i",
        }),
      })
      const { data } = await response.json()
      if (!data?.success) throw new Error("Failed to flag image")
      toast.success(
        "Image flagged. We have been notified and will take a look.",
      )
    } catch (error) {
      console.error(error)
      toast.error("Error flagging image 😢. We have been notified.")
    } finally {
      setOpen(false)
    }
  }

  return (
    <div>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogTrigger
          asChild
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setOpen(true)
          }}
        >
          <Button
            variant="ghost"
            size="icon"
            className={cn("hover:bg-destructive", buttonClassNames)}
          >
            <FlagIcon className="h-4 w-4" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogPortal>
          <AlertDialogOverlay
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setOpen(false)
            }}
          />
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Report image?</AlertDialogTitle>
              <AlertDialogDescription>
                Does this image violate our community guidelines? If so, please
                let us know.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setOpen(false)
                }}
              >
                Cancel
              </AlertDialogCancel>
              <AlertDialogAction onClick={onFlagClick}>
                Report
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogPortal>
      </AlertDialog>
    </div>
  )
}
