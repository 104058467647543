"use client"
import { getGenerationImage } from "@/utils/helpers"
import { GenerationImages } from "@/utils/types"
import { FeedCardDirectionAwareHoverImage } from "@/components/feed-card/feed-card-direction-aware-hover-image"

interface FeedCardDirectionAwareImageProps {
  images: GenerationImages
  children?: React.ReactNode
  imageClassName?: string
}

export function FeedCardDirectionAwareImage(
  props: FeedCardDirectionAwareImageProps,
) {
  const { images, children } = props

  const generationImage = getGenerationImage(images)

  return (
    <FeedCardDirectionAwareHoverImage
      generationImage={generationImage}
      generationImages={images}
      imageUrl={generationImage.url}
      imageClassName={props.imageClassName}
    >
      {children && children}
    </FeedCardDirectionAwareHoverImage>
  )
}
