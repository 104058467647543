import { Button } from "@/components/ui/button"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { TypographyP } from "@/components/ui/typography"
import { SquareStackIcon } from "lucide-react"

export const HasMultipleImagesBadge = () => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="cursor-default bg-black/40 rounded-lg"
          >
            <SquareStackIcon className="h-4 w-4 text-white" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <TypographyP>
            This generation has multiple images. Click to view them.
          </TypographyP>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
