import { z } from "zod"

export const GENERATIONS_PAGE_SIZE = 25

export const GenerationAspectRatiosZod = z.enum([
  "1:1",
  "1:2",
  "2:1",
  "3:4",
  "4:3",
  "9:16",
  "16:9",
])

export const GenerationAspectRatios = GenerationAspectRatiosZod.Enum

export enum GenerationTaskStatuses {
  PENDING = 0,
  GENERATING = 1,
  DELIVERED = 2,
  FAILED = 3,
  HIDDEN = 4,
}

export const WATERMARK_TEXT = "Generated by Pornstars.AI"

export enum CronTime {
  DAILY = "0 0 * * *",
  HOURLY = "0 * * * *",
  WEEKLY = "0 0 * * 0",
  MONTHLY = "0 0 1 * *",
  YEARLY = "0 0 1 1 *",
  EVERY_12_HOURS = "0 */12 * * *",
}
