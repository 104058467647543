import { cn } from "@/lib/utils"

interface FeedCardOveralyProps {
  children?: React.ReactNode
  overlayChildrenClassName?: string
}

export function FeedCardOveraly(props: FeedCardOveralyProps) {
  const { children, overlayChildrenClassName } = props

  return (
    <div>
      <div
        className={cn(
          "absolute inset-0 flex justify-center items-center",
          "opacity-0 group-hover:opacity-100",
          "bg-black/40 transition duration-400",
          //  "group-hover:bg-gradient-to-t group-hover:from-black/60 group-hover:via-black/40 group-hover:to-black/10",
          "rounded-lg",
          "cursor-pointer",
        )}
      />
      <div
        className={cn(
          "absolute z-20 inset-0 flex justify-center items-center",
          overlayChildrenClassName,
        )}
      >
        {children && children}
      </div>
    </div>
  )
}
