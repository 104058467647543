import { posthogServerClient } from "@/services/posthog"

export const trackServer = (event: string, properties?: any) => {
  posthogServerClient.capture({
    distinctId: properties.user_id,
    event,
    properties,
  })
}

export { posthogServerClient }
