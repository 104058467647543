"use client"

import { useRootStore } from "@/store/root-store"
import { GenerationsTableRowWithUsersProfile } from "@/utils/types/database"
import { User } from "@supabase/supabase-js"
import { useSearchParams } from "next/navigation"
import { MouseEvent, useCallback, useMemo, useState } from "react"
import { useCurrentUserLikes } from "@/hooks/use-current-user-likes"
import { Sentry } from "@/services/sentry"
import { toast } from "sonner"
import { upsertGenerationsLike } from "@/app/actions/likes.actions"
import { HoverAwareCard } from "@/components/text-to-image-card/text-to-image-hover-aware-card"
import { RegularCard } from "@/components/text-to-image-card/text-to-image-regular-card"
import { isEmpty } from "lodash"
import { getGenerationImages } from "@/utils/helpers"

interface TextToImageCardProps {
  generation: GenerationsTableRowWithUsersProfile
  user?: User | null
  index?: number
  imageClassName?: string
  isHoverAware?: boolean
  overlayChildrenClassName?: string
  scrollOnLinkClick?: boolean
}

export function TextToImageCard(props: TextToImageCardProps) {
  const {
    generation,
    imageClassName,
    user,
    isHoverAware,
    overlayChildrenClassName,
    scrollOnLinkClick,
  } = props
  const searchParams = useSearchParams()
  const [isLiking, setIsLiking] = useState(false)

  const { hasMultipleImages } = getGenerationImages(generation.images)

  const { toggleLike, likes } = useCurrentUserLikes()
  const {
    removeLikedGenerationByGenerationId,
    prependLikedGeneration,
    removeLikeByGenerationId,
    appendLike,
    incrementGenerationLikes,
    decrementGenerationLikes,
  } = useRootStore((store) => store)

  const likedDoc = likes[generation.id]

  const isLiked = !isEmpty(likedDoc)

  const searchParamsString = useMemo(() => {
    if (isLiked) {
      return searchParams.toString() + "&likeId=" + likedDoc.id
    }
    return searchParams.toString()
  }, [isLiked, searchParams, likedDoc])

  const redoLike = useCallback(async () => {
    if (!user?.id) {
      return
    }
    try {
      setIsLiking(true)

      const { data: likedGeneration, error: likeError } =
        await upsertGenerationsLike({
          generationId: generation.id,
          userId: user.id,
        })

      if (likeError) {
        console.error("Error liking generation:", likeError)
        toast("Error liking generation", {
          description: "Please try again",
        })
        Sentry.captureException(likeError)
        throw likeError
      }

      if (likedGeneration) {
        prependLikedGeneration(likedGeneration)
        appendLike(likedGeneration)
        incrementGenerationLikes(generation.id)
      }

      toast.dismiss()

      toast("Generation added to likes!")
    } catch (error) {
      console.error("error liking generation", error)
      Sentry.captureException(error)
      throw error
    } finally {
      setIsLiking(false)
    }
  }, [
    generation,
    user?.id,
    prependLikedGeneration,
    appendLike,
    incrementGenerationLikes,
  ])

  const onLikeClick = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setIsLiking(true)
      toast.dismiss()

      const { data: toggledLikeDoc, error: toggleLikeError } = await toggleLike(
        generation,
        isLiked,
      )

      if (toggleLikeError) {
        console.error("Error toggling like:", toggleLikeError)
        Sentry.captureException(toggleLikeError)
        setIsLiking(false)
        return
      }

      if (isLiked) {
        removeLikedGenerationByGenerationId(generation.id)
        removeLikeByGenerationId(generation.id)
        decrementGenerationLikes(generation.id)
        toast("Generation removed from likes", {
          description: "Want to like it again?",
          action: {
            label: "Undo",
            onClick: redoLike,
          },
        })
      } else {
        if (toggledLikeDoc) {
          prependLikedGeneration(toggledLikeDoc)
          appendLike(toggledLikeDoc)
          incrementGenerationLikes(generation.id)
        }
      }

      setIsLiking(false)
    },
    [
      toggleLike,
      generation,
      isLiked,
      prependLikedGeneration,
      redoLike,
      removeLikedGenerationByGenerationId,
      appendLike,
      removeLikeByGenerationId,
      incrementGenerationLikes,
      decrementGenerationLikes,
    ],
  )

  if (isHoverAware) {
    return (
      <HoverAwareCard
        user={user}
        generation={generation}
        imageClassName={imageClassName}
        searchParamsString={searchParamsString}
        isLiked={isLiked}
        isLiking={isLiking}
        onLikeClick={onLikeClick}
        scrollOnLinkClick={scrollOnLinkClick}
        hasMultipleImages={hasMultipleImages}
      />
    )
  }

  return (
    <RegularCard
      user={user}
      generation={generation}
      imageClassName={imageClassName}
      searchParamsString={searchParamsString}
      isLiked={isLiked}
      isLiking={isLiking}
      onLikeClick={onLikeClick}
      overlayChildrenClassName={overlayChildrenClassName}
      scrollOnLinkClick={scrollOnLinkClick}
      hasMultipleImages={hasMultipleImages}
    />
  )
}
