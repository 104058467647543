// file generated by the Paraglide-Next init command
import { Middleware, Navigation, PrefixStrategy } from "@inlang/paraglide-next"
import type { AvailableLanguageTag } from "@/paraglide/runtime"

export const strategy = PrefixStrategy<AvailableLanguageTag>({
  exclude: (pathname) => {
    return pathname.startsWith("/api") || pathname.startsWith("/_next")
  },
  pathnames: {
    "/terms": {
      en: "/terms",
      de: "/bedingungen",
      fr: "/conditions",
    },
  },
})

export const middleware = Middleware({ strategy })

export const { Link, useRouter, usePathname, redirect, permanentRedirect } =
  Navigation({ strategy })
