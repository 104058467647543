"use client"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { useRouter } from "@/lib/i18n"
import { Tables } from "@/utils/types/database"

interface OwnerAvatarProps {
  owner: Tables<"users_profiles">
}
export function OwnerAvatar(props: OwnerAvatarProps) {
  const { owner } = props
  const router = useRouter()

  const initials = owner?.pro_display_name
    ? `${owner.pro_display_name[0]}${owner.pro_display_name[1]}`
    : "N/A"

  const fallbackInitials = initials.toUpperCase()

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    event.preventDefault()
    router.push(`/fapper/${owner.id}`)
  }

  return (
    <Avatar
      className="size-8 hover:scale-110 transition-transform cursor-pointer hover:shadow-lg"
      onClick={onClick}
    >
      {owner.pro_picture_url && <AvatarImage src={owner.pro_picture_url} />}
      <AvatarFallback className="text-xs">{fallbackInitials}</AvatarFallback>
    </Avatar>
  )
}
