import { cn } from "@/lib/utils"
interface FeedCardItemProps {
  children?: React.ReactNode
  className?: string
}

export function FeedCardItem(props: FeedCardItemProps) {
  const { children, className } = props

  return (
    <div
      className={cn(
        "w-full aspect-auto mb-2",
        "relative group animate-fadeIn",
        className,
      )}
    >
      {children}
    </div>
  )
}
