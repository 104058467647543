"use client"
import { getGenerationImage } from "@/utils/helpers"
import Image from "next/image"
import { GenerationImages } from "@/utils/types"
import { cn } from "@/lib/utils"

interface FeedItemImageProps {
  images: GenerationImages
  children?: React.ReactNode
  imageClassName?: string
}

export function FeedCardImage(props: FeedItemImageProps) {
  const { images, children, imageClassName } = props

  const generationImage = getGenerationImage(images)

  return (
    <>
      <Image
        src={generationImage.url}
        alt="User generated image."
        width={generationImage.width}
        quality={100}
        height={generationImage.height}
        className={cn("rounded-lg", imageClassName)}
        placeholder={images.blurhash ? "blur" : "empty"}
        blurDataURL={images.blurhash}
        sizes="(max-width: 640px) 100vw,
      (max-width: 960px) 50vw,
      (max-width: 1280px) 33.3vw,
      25vw"
      />
      {children && children}
    </>
  )
}
