"use client"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { Loader2 } from "lucide-react"
import { MouseEvent } from "react"

interface FeedLikeButtonProps {
  likesCount: number | null
  isLiked?: boolean
  onLikeClick?: (event: MouseEvent<HTMLButtonElement>) => void
  buttonClassNames?: string
  isLiking?: boolean
}

export function FeedLikeButton(props: FeedLikeButtonProps) {
  const { likesCount, isLiked, onLikeClick, buttonClassNames, isLiking } = props

  return (
    <div>
      <Button
        variant="ghost"
        onClick={onLikeClick}
        size="icon"
        className={cn(buttonClassNames)}
      >
        {isLiking ? (
          <div>
            <Loader2 className="mr-1 w-5 h-5 text-primary/60 animate-spin" />
          </div>
        ) : (
          <div
            className={cn("mr-1", {
              grayscale: !isLiked,
            })}
          >
            ❤️
          </div>
        )}
        <span className="text-sm text-white">{likesCount}</span>
      </Button>
    </div>
  )
}
