import { GenerationAspectRatios } from "@/constants"
import { Database } from "@/utils/types/database"
import { GenerationImages } from "@/utils/types"
import { User } from "@supabase/supabase-js"

export const getGenerationImage = (images: GenerationImages) => {
  const generationImage = images?.hires_images?.[0] || images?.base_images?.[0]
  return generationImage
}

export const getGenerationImages = (images: GenerationImages) => {
  const featuredImage = getGenerationImage(images)

  const otherImages = images?.hires_images
    ? images?.hires_images.filter((image) => image.url !== featuredImage.url)
    : images?.base_images.filter((image) => image.url !== featuredImage.url)

  return {
    featuredImage,
    otherImages,
    allImages: [featuredImage, ...otherImages],
    hasMultipleImages: otherImages.length > 0,
  }
}

export const getPercentageOf = (value: number, percentage: number) => {
  return value * (percentage / 100)
}
export const getGenerationDownscaleValueForAspectRatio = (
  aspectRatio: Database["public"]["Enums"]["aspect_ratio"] | null,
) => {
  switch (aspectRatio) {
    case GenerationAspectRatios["9:16"]:
      return 60
    case GenerationAspectRatios["1:2"]:
      return 80
    case GenerationAspectRatios["3:4"]:
      return 80
    default:
      return 100
  }
}

export const getColSpanSplitForAspectRatio = (
  aspectRatio: Database["public"]["Enums"]["aspect_ratio"] | null,
) => {
  switch (aspectRatio) {
    case GenerationAspectRatios["2:1"]:
      return [1, 3]
    case GenerationAspectRatios["4:3"]:
      return [1, 3]
    case GenerationAspectRatios["1:1"]:
      return [2, 2]
    case GenerationAspectRatios["9:16"]:
      return [2, 2]
    case GenerationAspectRatios["16:9"]:
      return [1, 3]
    default:
      return [2, 2]
  }
}

export const getIsUserPro = (user?: User | null) => {
  return user?.app_metadata?.is_pro || false
}

export const getIsUserAdmin = (user?: User | null) => {
  return user?.app_metadata?.claims_admin || false
}

export const getIsUserModerator = (user?: User | null): boolean => {
  const isModerator = user?.app_metadata?.roles?.includes("moderator")
  return isModerator || false
}

export const getIsUserBanned = (user?: User | null) => {
  return user?.app_metadata?.is_banned || false
}
