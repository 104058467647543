import { FeedCard } from "@/components/feed-card"
import { FeedCardFlagButton } from "@/components/feed-card/feed-card-flag-button"
import { FeedLikeButton } from "@/components/feed-card/feed-like-button"
import { OwnerAvatar } from "@/components/owner-avatar/owner-avatar"
import { TextToImageCardProps } from "@/components/text-to-image-card/text-to-image-card.props"
import { Button } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { EyeIcon } from "lucide-react"
import { Link } from "@/lib/i18n"
import { HasMultipleImagesBadge } from "@/components/text-to-image-card/has-multiple-images-badge"

export function RegularCard(props: TextToImageCardProps) {
  const {
    generation,
    imageClassName,
    searchParamsString,
    isLiked,
    isLiking,
    onLikeClick,
    overlayChildrenClassName,
    scrollOnLinkClick = false,
    hasMultipleImages = false,
  } = props
  const owner_profile = generation?.users_profiles ?? null

  return (
    <Link
      key={generation.id}
      href={`/images/${generation.id}?${searchParamsString}`}
      scroll={scrollOnLinkClick}
    >
      <FeedCard.Item>
        <FeedCard.Image
          images={generation.images}
          imageClassName={imageClassName}
        >
          <FeedCard.Overlay overlayChildrenClassName={overlayChildrenClassName}>
            <div
              className={cn(
                "flex flex-col h-full w-full items-start justify-between",
                "p-2 space-y-4",
                "rounded-lg",
              )}
            >
              <div className="flex items-center justify-between w-full">
                <div></div>
                <div>{hasMultipleImages && <HasMultipleImagesBadge />}</div>
              </div>
              <div className="flex items-center justify-between w-full">
                <div className="w-12 h-12 flex items-center justify-center">
                  {owner_profile && <OwnerAvatar owner={owner_profile} />}
                </div>
                <div className="flex flex-row bg-black/40 rounded-lg">
                  <FeedCardFlagButton
                    generation={generation}
                    buttonClassNames="rounded-none rounded-l-lg"
                  />
                  <div>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="rounded-none cursor-default"
                    >
                      <EyeIcon className="h-4 w-4 mr-1 text-white" />
                      <span className="text-sm text-white">
                        {generation.views_count}
                      </span>
                    </Button>
                  </div>
                  <FeedLikeButton
                    buttonClassNames="rounded-none rounded-r-lg"
                    likesCount={generation.likes_count}
                    isLiked={isLiked}
                    onLikeClick={onLikeClick}
                    isLiking={isLiking}
                  />
                </div>
              </div>
            </div>
          </FeedCard.Overlay>
        </FeedCard.Image>
      </FeedCard.Item>
    </Link>
  )
}
