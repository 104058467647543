const title = "Pornstars.AI | AI Generated Porn"
const description = "Create your own porn using AI."
const site_name = "Pornstars.AI"
const keywords = "pornstars, porn, ai, ai porn, pornstars.ai"
const production_url = "https://pornstars.ai"

const default_seo_image_url =
  "https://skkapzfkaozngwpiuoqs.supabase.co/storage/v1/object/public/marketing/SEO/SEO_Banner.jpg"

export const SEO_CONSTANTS = {
  title,
  description,
  site_name,
  keywords,
  default_seo_image_url,
  production_url,
}
