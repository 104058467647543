"use client"
import { CurrentUserLikesContext } from "@/hooks/use-current-user-likes/current-user-likes-provider"
import { useContext } from "react"

export const useCurrentUserLikes = () => {
  const context = useContext(CurrentUserLikesContext)
  if (context === undefined) {
    throw new Error(
      "useCurrentUserLikes must be used within a CurrentUserLikesProvider",
    )
  }
  return context
}
