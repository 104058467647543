"use client"

import { useRef, useState } from "react"
import Image from "next/image"
import { AnimatePresence, motion } from "framer-motion"
import { cn } from "@/lib/utils"
import { GenerationImage, GenerationImages } from "@/utils/types"

export const FeedCardDirectionAwareHoverImage = (props: {
  imageUrl: string
  children: React.ReactNode | string
  childrenClassName?: string
  imageClassName?: string
  className?: string
  generationImage: GenerationImage
  generationImages: GenerationImages
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const {
    children,
    childrenClassName,
    imageClassName,
    className,
    generationImage,
    generationImages,
  } = props

  const [direction, setDirection] = useState<
    "top" | "bottom" | "left" | "right" | string
  >("left")

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!ref.current) return

    const direction = getDirection(event, ref.current)

    switch (direction) {
      case 0:
        setDirection("top")
        break
      case 1:
        setDirection("right")
        break
      case 2:
        setDirection("bottom")
        break
      case 3:
        setDirection("left")
        break
      default:
        setDirection("left")
        break
    }
  }

  const getDirection = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>,
    obj: HTMLElement,
  ) => {
    const { width: w, height: h, left, top } = obj.getBoundingClientRect()
    const x = ev.clientX - left - (w / 2) * (w > h ? h / w : 1)
    const y = ev.clientY - top - (h / 2) * (h > w ? w / h : 1)
    const d = Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4
    return d
  }

  return (
    <motion.div
      onMouseEnter={handleMouseEnter}
      ref={ref}
      className={cn(
        "bg-transparent rounded-lg overflow-hidden group/card relative",
        className,
      )}
    >
      <AnimatePresence mode="wait">
        <motion.div
          className="relative h-full w-full"
          initial="initial"
          whileHover={direction}
          exit="exit"
        >
          <motion.div className="group-hover/card:block hidden absolute inset-0 w-full h-full bg-black/40 z-10 transition duration-500 pointer-events-none" />
          <motion.div
            variants={variants}
            className="h-full w-full relative bg-gray-50 dark:bg-black"
            transition={{
              duration: 0.2,
              ease: "easeOut",
            }}
          >
            <Image
              src={generationImage.url}
              alt="User generated image."
              width={generationImage.width}
              quality={100}
              height={generationImage.height}
              className={cn(
                "rounded-lg h-full w-full object-cover scale-[1.15]",
                imageClassName,
              )}
              placeholder={generationImages.blurhash ? "blur" : "empty"}
              blurDataURL={generationImages.blurhash}
              sizes="(max-width: 640px) 100vw,
                (max-width: 960px) 50vw,
                (max-width: 1280px) 33.3vw,
                25vw"
            />
          </motion.div>
          <div className="absolute z-20 inset-0 flex justify-center items-center">
            {children}
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  )
}

const variants = {
  initial: {
    x: 0,
  },
  exit: {
    x: 0,
    y: 0,
  },
  top: {
    y: 20,
  },
  bottom: {
    y: -20,
  },
  left: {
    x: 20,
  },
  right: {
    x: -20,
  },
}
